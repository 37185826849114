import React from 'react';
import { graphql } from 'gatsby';
// import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/helpers';
import Leads from '../components/Leads';
// import PipedriveForm from '../components/PipedriveForm';
import Heading from '../components/Heading/Heading';
// import { SocialLinks } from '../components/SocialLinks'
import { Breadcrumbs } from '../components/Breadcrumbs';
import './contact-page.scss';

const ContactPage = ({ data, location }) => {
  const { wordpressPost: page, wordpressWpSettings, siteSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  if (!page) return null;
  const { title, yoast, content } = page;
  const { title: siteTitle } = wordpressWpSettings;
  const {
    email,
    phone,
    addressStreet,
    addressCity,
    addressPostCode,
    googleMap,
    locationName,
  } = siteSettings.options.locations[0];
  const { socialLinks } = siteSettings.options;

  const addressLine = `${decodeEntities(addressStreet)} ${decodeEntities(addressCity)} ${decodeEntities(addressPostCode)}`

  const canonicalAttr = yoast.canonical ? yoast.canonical : `${wordpressWpSettings.siteUrl}/${page.slug}/`;

  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        canonical={canonicalAttr}
      />
      <section className="contact-page-section">
        <div className="wrap">
          <div className="contact-details">
            <div className="inner">
              <Breadcrumbs location={location} />
              <Heading tag="h1" className="page-title">{decodeEntities(title)}</Heading>
              <div className="content" dangerouslySetInnerHTML={{__html: content}} />
              {phone && (
                <div className="contact-section">
                  <span className="contact-title">Phone:</span>
                  <a href={`tel:${phone}`}>{decodeEntities(phone)}</a>
                </div>
              )}
              {email && (
                <div className="contact-section">
                  <span className="contact-title">Email:</span>
                  <a href={`mailto:${decodeEntities(email)}`}>{decodeEntities(email)}</a>
                </div>
              )}
              {addressLine && (
                <div className="contact-section">
                  <span className="contact-title">Office:</span>
                  <span>
                    <a href='https://www.google.com/maps/search/Revell+Landscaping+Perth/@-31.9141764,115.8954891,14z/data=!3m1!4b1?hl=en&entry=ttu'>{addressLine}</a>
                    <br />(By appointment only)</span>
                </div>
              )}
              {googleMap && (
                <div className="map-container">
                  <iframe
                    src={googleMap}
                    title={`${locationName} Google Map`}
                    height="380"
                    frameBorder="0"
                  />
                </div>
              )}
              <div className="contact-section">
                <span className="contact-title">Follow us to see our latest work:</span>
                {/* <SocialLinks/> */}
                <div className="social-links">
                  <ul>
                    {socialLinks && socialLinks.map((item, index) => (
                      <li key={index} >
                        <a
                          className={item.type}
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >

                        {item.type === 'houzz' && (
                          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>Houzz</title>
                            <g fillRule="nonzero" fill="#8EC29A" stroke="none" strokeWidth="1">
                              <path d="M12.2 10.3l-3.7 1.6 3.3 2 3.5-1.8zM8 16l2.3-1.4-2.2-1.3zM12.7 19l3.2-1.5v-4.1L12.7 15z"/>
                              <path d="M12 0a12 12 0 100 24 12 12 0 000-24zm5.3 12v6c0 .3-.2.5-.5.6l-4.6 2a.7.7 0 01-.6 0 .7.7 0 01-.3-.6v-4.4l-3.6 2.1a.7.7 0 01-1-.6v-5-.2-5.7c0-.2.1-.5.4-.6l4.6-2.3a.7.7 0 011 .6v4.6l3.5-2.1h.7c.2.1.3.4.4.6v5z"/>
                              <path d="M11.3 5L8 6.6v4l3.2-1.4zM15.9 10.9V8.2l-2.3 1.3z"/>
                            </g>
                          </svg>                     
                        )}

                        {item.type === 'instagram' && (
                          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>Instagram</title>
                            <g fillRule="nonzero" fill="#8EC29A" stroke="none" strokeWidth="1">
                              <path d="M14.7 6.6H9.2a2.7 2.7 0 00-2.6 2.7v5.4c0 1.5 1.2 2.7 2.6 2.7h5.5c1.5 0 2.7-1.2 2.7-2.7V9.3c0-1.5-1.2-2.7-2.7-2.7zM12 15.5a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm3.5-6.1a.8.8 0 110-1.7.8.8 0 010 1.7z"/>
                              <path d="M12 9.9a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2z"/>
                              <path d="M12 0a12 12 0 100 24 12 12 0 000-24zm6.7 14.7a4 4 0 01-4 4H9.2a4 4 0 01-4-4V9.3a4 4 0 014-4h5.5a4 4 0 014 4v5.4z"/>
                            </g>
                          </svg>
                        )}

                        {item.type === 'pinterest' && (
                          <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>Pinterest</title>
                            <path d="M12 0a12 12 0 100 24 12 12 0 000-24zm1 16c-1 0-1.4-.5-2-1-.5 2.1-1 4-2.3 5.1-.5-3 .6-5.4 1.1-7.9-.9-1.4.1-4.3 1.9-3.6 2.2.9-2 5.4.9 6 2.8.5 4-5.1 2.2-7-2.6-2.6-7.5 0-6.9 3.8.1.9 1.1 1.2.4 2.4-1.7-.3-2.2-1.6-2.1-3.4 0-2.8 2.6-4.8 5-5.1 3.1-.4 6 1.1 6.5 4 .4 3.4-1.4 7-4.8 6.7z" fillRule="nonzero" fill="#8EC29A" stroke="none" strokeWidth="1"/>
                          </svg>
                        )}

                        {item.type === 'facebook' && (
                          <svg width="25" height="24" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>Facebook</title>
                            <path d="M12.2 0a12 12 0 100 24 12 12 0 000-24zm4 7.2c0 .2-.2.3-.4.3h-1.2c-1 0-1 .3-1 1V10h2.1c.2 0 .3.1.3.3v2.4a.3.3 0 01-.3.3h-2.2v6c0 .2-.1.3-.3.3h-2.4a.3.3 0 01-.3-.3v-6h-2a.3.3 0 01-.2-.3v-2.4c0-.2.1-.3.3-.3h1.9V8.3c0-2.2 1.3-3.6 3.4-3.6h2s.2.1.2.3v2.2z" fillRule="nonzero" fill="#8EC29A" stroke="none" strokeWidth="1"/>
                          </svg>
                        )}

                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="form-container">
            {/* <PipedriveForm /> */}
            <Leads id={15} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage;

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    wordpressWpSettings {
      title
      siteUrl
      wordpressUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        locations {
          addressStreet
          addressCity
          addressRegion
          addressPostCode
          email
          phone
          googleMap
          locationName
        }
        socialLinks {
          type
          url
        }
      }
    },
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_id
      slug
      yoast {
        metaTitle: title
        metaDescription: metadesc
        canonical: canonical
      }
    }
  }
`
